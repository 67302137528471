<template>
    <div
        v-if="breadcrumbs"
        class="my-auto flex h-full flex-row text-sm text-slate-400 max-sm:hidden"
    >
        <div v-for="(link, index) in breadcrumbs" :key="index">
            <div v-if="link.name !== ''" class="mr-1 flex flex-row">
                <nuxt-link class="hover:underline" :to="link.path">
                    {{ link.name }}
                </nuxt-link>
                <div v-if="index < breadcrumbs.length - 1" class="ml-1">|</div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const { breadcrumbs } = useBreadcrumbs();
</script>
