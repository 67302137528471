type BreadCrumb = {
    name: string;
    path: string;
};

const router = useRouter();

export default () => {
    const { t } = useI18n();

    const route = useRoute();

    const breadcrumbs: Ref<BreadCrumb[] | undefined> = ref();

    watch(
        () => ({
            path: route.path,
            name: route.name,
            meta: route.meta,
        }),
        (route) => {
            if (route.path === "/") return;

            breadcrumbs.value = getBreadcrumbs(route.path);
        },
        {
            immediate: true,
        },
    );

    function getBreadcrumbs(currPath: string): BreadCrumb[] {
        if (currPath === "") return [{ name: "", path: "" }];

        const parentRoutes = getBreadcrumbs(
            currPath.slice(0, currPath.lastIndexOf("/")),
        );

        const route = router.resolve(currPath);

        const name = ref<string>("");

        if (route.meta?.displayName) {
            if (route.meta?.pluralName) {
                name.value = t(route.meta?.displayName as string, 2);
            } else {
                name.value = t(route.meta?.displayName as string);
            }

            if ("id" in route.params && route.meta?.hasDisplayId) {
                name.value += ` #${route.params.id}`;
            }
        } else {
            name.value = "";
        }

        return [
            ...parentRoutes,
            {
                path: currPath,
                name: name.value,
            },
        ];
    }

    return {
        breadcrumbs,
    };
};
