<template>
    <div class="theme-merchant">
        <smu-toast :toasts="toasts" />
        <smu-site title="Merchant" :sidebar-shown="sidebarOpen">
            <template #header>
                <smu-header
                    ref="headerRef"
                    :title="appName"
                    :subtitle="$t('frontend.merchant_administration')"
                >
                    <template #sidebar-toggle>
                        <smu-show-sidebar-toggle v-model="sidebarOpen" />
                    </template>
                    <template #center>
                        <page-header-edit-state v-if="hasChanges" />
                        <breadcrumbs v-if="!hasChanges && isNormalPage" />
                    </template>
                    <template #right-side>
                        <div v-if="!hasChanges" class="relative">
                            <language-dropdown></language-dropdown>
                        </div>
                        <div
                            v-if="user && !hasChanges"
                            data-testid="header-user-menu"
                        >
                            <smu-user-menu :user="user">
                                <smu-user-menu-item
                                    data-testid="header-user-menu-change-password"
                                    icon="pencil"
                                >
                                    {{ $t("helpers.buttons.change_password") }}
                                </smu-user-menu-item>
                                <smu-user-menu-item
                                    data-testid="header-user-menu-logout"
                                    icon="arrow-right-from-bracket"
                                    @click="logout"
                                >
                                    {{ $t("helpers.buttons.logout") }}
                                </smu-user-menu-item>
                            </smu-user-menu>
                        </div>
                    </template>
                    <span>{{ route.meta.title?.() }}</span>
                </smu-header>
            </template>
            <template v-if="user" #menue>
                <div class="flex h-full flex-col justify-between">
                    <div><page-main-menu /></div>
                    <div><page-default-footer /></div>
                </div>
            </template>
            <slot />
        </smu-site>
    </div>
</template>

<script setup lang="ts">
import { SmuHeader } from "@smakecloud/smake-ui";
import { aliasMap } from "~/lib/shortcuts";

const { useToast } = await import("@smakecloud/smake-ui");

const { data } = storeToRefs(useUserStore());

const route = useRoute();
const sidebarStore = useSidebarStore();
const { sidebarOpen } = storeToRefs(sidebarStore);

const user = data;

const appName = computed(() => {
    return data.value?.app.display_name ?? "";
});

const { logout } = useTokenStore();

const { hasChanges } = useUnsavedChangesHeader();

const { toasts } = useToast();

useHead({
    titleTemplate: (title) =>
        title
            ? `${title} - Smake Merchant Management`
            : "Smake Merchant Management",
    meta: [{ property: "og:title", content: "Smake Merchant Management" }],
});

const isNormalPage = computed(() => {
    if (isGuestPage(route) || is404Page(route)) return false;

    return true;
});

const keys = useMagicKeys({
    aliasMap,
    passive: false,
});

whenever(keys.escape, () => {
    sidebarOpen.value = false;
});
</script>
